import React, { Component } from 'react';

// *******************************************************
// HANDLE COMPONENT
// *******************************************************

export class Handle extends Component {
  render() {
    const { domain, handle, getHandleProps } = this.props;

    return (
      <div
        className="handle"
        role="slider"
        aria-valuemin={domain[0]}
        aria-valuemax={domain[1]}
        aria-valuenow={handle.value}
        style={{
          left: `${handle.percent}%`,
        }}
        {...getHandleProps(handle.id)}>
      </div>
    )
  }
}


// *******************************************************
// TRACK COMPONENT
// *******************************************************

export class Track extends Component {
  render() {
    const { source, target, getTrackProps } = this.props;

    return (
      <div
        className="track"
        style={{
          left: `${source.percent}%`,
          width: `${target.percent - source.percent}%`
        }}
        {...getTrackProps()}
      />
    )
  }
}