import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from "react-redux";
import configureStore, { history } from './app/store'
import { loadState } from './app/localStorage';
import App from './app/containers/App';
import './scss/style.scss';

const preloadedState = loadState();
console.log(preloadedState);
const store = configureStore(preloadedState)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>, 
  document.getElementById('scf-root'));