import React from 'react';
import _ from 'lodash';
import { connect } from "react-redux";
import BaseComponent from './BaseComponent';
import { closeSummaryPopup } from '../actions/config';
import Button from './Button';

class Summary extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { }
  }

  getProduct() {
    return _.find(this.props.financing.products, (o)=>{ return o.ident === this.props.financing.current.ident });
  }

  render() {
    const { financing } = this.props;
    const product = this.getProduct();
    const isLeasing = !financing.current || financing.current.rrso === '' ? true : false;
    return (
      <div className="summary">
          <h2 className="car-price-heading">
            <span className="car-price-heading--text">Cena samochodu: <span className="bold">{ this.thousandSep(financing.carConfig.car_price)+' zł' }</span></span>
          </h2>
          <div className="product-summary">
            <h3>{ isLeasing ? 'Leasing dla przedsiębiorców' : 'Kredyt' }</h3>
            <span className="desc">Przykład finansowania</span>
            <div className="summary-table">
              <div className="product-config">
                <div className="product-config-cell product-config-cell-monthly">
                  <span className="cell-title">Miesięczna rata</span>
                  <span className="cell-value">{ this.thousandSep(Math.round(financing.current.monthly))+' zł' }</span>
                </div>
                <div className="product-config-cell">
                  <span className="cell-title">{ product.params[0].name }</span>
                  <span className="cell-value">{ product.params[0].steps[this.props.financing.current.params[0].value] }<span>{ product.params[0].unit }</span></span>
                </div>
                <div className="product-config-cell">
                  <span className="cell-title">{ product.params[1].name }</span>
                  <span className="cell-value">{ product.params[1].steps[this.props.financing.current.params[1].value] } <span className="units">{ product.params[1].unit }</span></span> 
                  <span className="cell-small"></span>
                </div>
                { product.params.length > 2 ?
                  <div className="product-config-cell">
                    <span className="cell-title">{ product.params[2].name }</span>
                    <span className="cell-value">{ this.thousandSep(product.params[2].steps[this.props.financing.current.params[2].value]) } <span className="units">{ product.params[2].unit }</span></span>
                  </div> : ''
                }
              </div>
            </div>
          </div>
          { /*!isLeasing ? 
            <div className="product-details product-summary">
              <ul> 
                <li>{ 'Wysokość raty miesięcznej: '+this.thousandSep(financing.current.monthly)+ ' zł' }</li>
                <li>{ 'Prowizja: '+this.thousandSep(financing.current.commission)+' zł' }</li>
                <li>{ 'Kwota kredytu: '+this.thousandSep(financing.current.gross_amount_without_commission)+' zł'}</li>
                <li>{ 'Całkowita kwota do zapłaty: '+this.thousandSep(Math.round(100*(parseFloat(financing.current.total_cost_without_assurances) + parseFloat(financing.current.gross_amount_without_commission)))/100)+' zł'}</li>
                <li className="no-border">{ 'Całkowity koszt kredytu: '+this.thousandSep(financing.current.total_cost_without_assurances)+' zł'}</li>
                <li className="no-border">{ 'Oprocentowanie stałe: '+financing.current.barem_rate.toString().replace('.', ',')+'%' }</li>
              </ul>
              <div className="disclaimer">Powyższa kalkulacja ma charakter informacyjny i nie stanowi przyrzeczenia udzielenia kredytu na takich warunkach. Dostępność produktu oraz warunki kredytowania uzależnione są od wyniku przeprowadzonej przez Bank analizy zdolności kredytowej Klienta na podstawie przekazanych przez niego informacji.</div>
            </div> : 
            <div className="product-details product-summary">
              <ul>
                <li>{ 'Wysokość raty miesięcznej: '+this.thousandSep(Math.round(financing.current.monthly))+' zł' }</li>
                <li>{ 'Opłata wstępna: '+this.thousandSep(Math.round(financing.current.downpayment_amount))+' zł ('+Math.round(financing.current.downpayment_pct)+'%)' }</li>
                <li className="no-border">{ 'Wykup: '+Math.round(financing.current.final_balance_amount)+' zł ('+Math.round(financing.current.final_balance_pct)+'%)'  }</li>
                <li className="no-border">{ 'Łączna suma opłat: '+Math.round(financing.current.total_leasing_cost_pct).toString().replace('.', ',')+'%' }</li>
              </ul>
            </div>
          */}
          {/*<div className="financial-advisor">Doradca ds. finansowania Mariusz Naumowicz 518 013 747 <br/><span>opłata za połączenie zgodnie z taryfą operatora</span></div>*/}
          <Button type="long" action={ this.props.closeSummaryPopup }>Zamknij</Button>
          { isLeasing ? <div className="disclaimer" dangerouslySetInnerHTML={{ __html: this.removeWhiteSpaceFromNumbers(product.disclaimer_1) }}></div> :
          <div className="disclaimer" dangerouslySetInnerHTML={{ __html: this.removeWhiteSpaceFromNumbers(product.disclaimer_2) }}></div> }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, financing, config } = state
  return { screen, financing, config }
};

export default connect(
  mapStateToProps,
  {
    closeSummaryPopup
  }
)(Summary);