import React from 'react';
import _ from 'lodash';
import { connect } from "react-redux";
import BaseComponent from './BaseComponent';
import { financingInit, setFinancingType, setInitialPayment, setPaymentsTime, setMilage, openPopup } from '../actions/financing';
import Slider from 'react-input-slider';
import Button from './Button';
import MonthlyBox from './MonthlyBox';
import RangeSlider from './slider/RangeSlider'
import { resize, openSummaryPopup } from '../actions/config';

class Calculation extends BaseComponent {

  constructor(props) {
    super(props)
    this.state = { 
      leasingSwitchIndex: this.props.financing.products.findIndex((o)=>{ return o.ident === this.props.financing.selected})
    }
    this._bind('setFinancingType', 'createOptions', 'onChangeInitial', 'onChangeCount')
  }

  componentWillMount() {
    
  }

  componentWillUpdate(nextProps) {

  }

  componentDidUpdate() {

  }

  setFinancingType(key) {
    this.setState({ leasingSwitchIndex: key })
    this.props.setFinancingType(key)
  }

  onChangeInitial(values, item) {
    this.props.setInitialPayment(item.steps.indexOf(values[0]), true);
  };

  onUpdateInitial = (values, item) => {
    //this.setState({ initial: values[0] });
    if (item.steps[this.props.financing.current.params[0].value] != values[0]) {
      this.props.setInitialPayment(item.steps.indexOf(values[0]), false);
    }
  };

  onChangeCount(values, item) {
    this.props.setPaymentsTime(item.steps.indexOf(values[0]), true);
  };

  onUpdateCount = (values, item) => {
    //this.setState({ count: values[0] });
    if (item.steps[this.props.financing.current.params[1].value] != values[0]) {
      this.props.setPaymentsTime(item.steps.indexOf(values[0]), false);
    }
  };

  onUpdateMilage = (values, item) => {
    //this.setState({ count: values[0] });
    if (item.steps[this.props.financing.current.params[2].value] != values[0]) {
      this.props.setMilage(item.steps.indexOf(values[0]), false);
    }
  };

  onChangeMilage = (value, item) => {
    if (item.steps[this.props.financing.current.params[2].value] != value) {
      this.props.setMilage(item.steps.indexOf(value), false);
    }
  };

  getProduct() {
    return _.find(this.props.financing.products, (o)=>{ return o.ident === this.props.financing.current.ident });
  }

  createOptions(product) {
    return (
      product.params.map((item, key)=>{
        switch (item.ident) {
          case 'initial':
            return (
              <div key={'param-'+key} className="option">
                <div className="option-title">{ item.name+': ' }<span>{item.steps[this.props.financing.current.params[0].value]}</span><span>{ item.unit }</span></div>
                <RangeSlider
                  domain={[item.steps[0], item.steps[item.steps.length-1]]}
                  values={[item.steps[this.props.financing.current.params[0].value]]}
                  step={ item.steps[1] - item.steps[0] }
                  unit={ item.unit }
                  onChange={(values)=>{this.onChangeInitial(values, item)}}
                  onUpdate={(values)=>{this.onUpdateInitial(values, item)}}
                  onSlideStart={this.onSlideStart}
                  showDots={ true } />
              </div>
            )
          case 'count':
            if (item.steps.length > 2) {
              return (
                <div key={'param-'+key} className="option">
                  <div className="option-title">{ item.name+': ' }<span>{item.steps[this.props.financing.current.params[1].value]}</span> <span>{ item.unit }</span></div>
                  <RangeSlider
                    domain={[item.steps[0], item.steps[item.steps.length-1]]}
                    values={[item.steps[this.props.financing.current.params[1].value]]}
                    step={ item.steps[1] - item.steps[0] }
                    unit={ '' }
                    onChange={(values)=>{this.onChangeCount(values, item)}}
                    onUpdate={(values)=>{this.onUpdateCount(values, item)}}
                    onSlideStart={this.onSlideStart}
                    showDots={ true } />
                </div>
              )
            } else {
              return (
                <div key={'param-'+key} className="option">
                  <div className="option-title">{ item.name+': ' }<span>{item.steps[this.props.financing.current.params[1].value]}</span> <span>{ item.unit }</span></div>
                  <div className="radios">
                    { item.steps.map((radio, k) => {
                      return (
                        <div key={ 'switch'+k } className={ this.props.financing.current.params[1].value === k ? "radio active" : "radio" } onClick={()=>{this.onChangeCount(item.steps[k], item)}}>{ item.steps[k] }</div>
                      )
                    })}
                  </div>
                </div>
              )
            }
          case 'additionalRange':
            if (item.steps.length > 2) {
              return (
                <div key={'param-'+key} className="option">
                  <div className="option-title">{ item.name+': ' }<span>{item.steps[this.props.financing.current.params[2].value]}</span> <span>{ item.unit }</span></div>
                  <RangeSlider
                    domain={[item.steps[0], item.steps[item.steps.length-1]]}
                    values={[item.steps[this.props.financing.current.params[2].value]]}
                    step={ item.steps[1] - item.steps[0] }
                    unit={ '' }
                    onChange={(values)=>{this.onChangeMilage(values, item)}}
                    onUpdate={(values)=>{this.onUpdateMilage(values, item)}}
                    onSlideStart={this.onSlideStart}
                    showDots={ true } />
                </div>
              )
            } else {
              return (
                <div key={'param-'+key} className="option" style={product.name==="Kredyt" ? {display:"none"}:{}}>
                  <div className="option-title">{ item.name+': ' }<span>{item.steps[this.props.financing.current.params[2].value]}</span> <span>{ item.unit }</span></div>
                  <div className="radios">
                    { item.steps.map((radio, k) => {
                      return (
                        <div key={ 'switch'+k } className={ this.props.financing.current.params[2].value === k ? "radio active" : "radio" } onClick={()=>{this.onChangeMilage(item.steps[k], item)}}>{ item.steps[k]+' '+item.unit }</div>
                      )
                    })}
                  </div>
                </div>
              )
            }
        }
      })
    )
  }

  render() {
    const { financing } = this.props;
    const { leasingSwitchIndex } = this.state;
    const product = this.getProduct();
    const isLeasing = !financing.current || financing.current.rrso === '' ? true : false;

    return (
      <div className="calculation">
        { financing.initPreloader ? null :
        <React.Fragment>
          <div className="radios">
            { financing.products.map((item, key) => {
              return (
                <div key={ 'switch'+key } className={ leasingSwitchIndex === key ? "radio active" : "radio" } onClick={ ()=>{this.setFinancingType(key)} }>{ item.name }</div>
              )
            })}
          </div>
          <MonthlyBox isLeasing={ isLeasing }/>
          <div className="financing-options">
            { this.createOptions(product) }
          </div>
          <Button type="summary" action={ this.props.openSummaryPopup }>Zobacz szczegółową ofertę</Button>
        </React.Fragment> }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { screen, financing } = state
  return { screen, financing }
};

export default connect(
  mapStateToProps,
  {
    financingInit,
    setFinancingType,
    setInitialPayment,
    setPaymentsTime,
    setMilage,
    openPopup,
    resize,
    openSummaryPopup
  }
)(Calculation);