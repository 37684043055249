import React from 'react'
import { connect } from "react-redux";
import { openRrsoPopup } from '../actions/financing';
import { openSummaryPopup } from '../actions/config';
import BaseComponent from './BaseComponent';
//import { NavLink } from 'react-router-dom';

class MonthlyBox extends BaseComponent {

  constructor(props) {
    super(props)
    this.dispatch = this.props.dispatch
  }

  toggleDisclaimer() {
    if ( this.props.screen.width <= 481 ) {
      this.props.toggleDisclaimer();
    }
  }

  disclaimerOn() {
    //console.log(this.props)
    if ( this.props.screen.width > 481 ) {
      this.props.disclaimerOn();
    }
  }

  disclaimerOff() {
    if ( this.props.screen.width > 481 ) {
      this.props.disclaimerOff();
    }
  }

  render() {
    const { financing, isLeasing } = this.props;
    let msg = <span>{ this.thousandSep(Math.round(financing.current.monthly)) }<span className="small"> zł</span></span>
    let txt = isLeasing ? 'miesięczna rata netto' : 'miesięczna rata';
    if ( this.props.error ) {
      msg = <span className="small">nieprawidłowe parametry</span>
      txt = ''
    }

    let rrso = this.props.rrso
    rrso = '15,50' // CHWILOWE
    if ( rrso === null ) {
      rrso = '...'
    }

    return (
      <div className={ isLeasing ? "monthly-fee monthly-fee-leasing" : "monthly-fee"}>
        {financing.failMessage ? 
          <p className="financing-error">{financing.failMessage}</p>
          :
          <>
            <span className="sub-title">{ isLeasing ? 'Przykład leasingu dla przedsiębiorców' : 'Przykład kredytu' }</span>
            <div className="price-wrap">
              <div className="scf-price">
                { msg }
              </div>
              <div className="i-icon" onClick={ this.props.openSummaryPopup }>i</div>
            </div>
            <span className="fee-title">{ txt }</span>
            { financing.preloader ? <div className="monthly-loader"></div> : null }
            <div className="credit-disclaimer">
              { !isLeasing ? 
                <div className="credit-disclaimer-wrap">
                  <div className="rrso">{ 'RRSO: '+rrso+'%' }<div className="i-icon" onClick={ this.props.openRrsoPopup }>i</div></div>
                </div>
                : '' }
            </div>
          </> }
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { screen, financing } = state
  return { screen, financing }
};

export default connect(
  mapStateToProps,
  {
    openRrsoPopup,
    openSummaryPopup
  }
)(MonthlyBox);
