import React from 'react';
import { connect } from "react-redux";
import { resize } from '../actions/config';
import BaseComponent from './BaseComponent';

class Header extends BaseComponent {

  resize() {

  }

  render() {

    let target = "maxi";
    let heading = <h2 className="bold">Dopasuj finansowanie</h2>;
    if ( this.props.config.size != 'maxi' ) {
      heading = <h2 className="bold">Finansowanie Bank&nbsp;BNP&nbsp;Paribas</h2>;
    }
    if ( this.props.config.size === 'maxi') {
      target = "mini"
    }
    if ( this.props.config.size === 'mini') {
      target = "micro"
    }
    if ( this.props.config.size === 'micro') {
      target = "mini"
    }
    return (
      <header className="header" id="widget-header">
        { heading }
        <div className="resize-btn" onClick={ ()=>{ this.props.resize(target) } }></div>
      </header>
    )
  }
}

const mapStateToProps = (state) => {
  const { config } = state
  return { config }
};

export default connect(
  mapStateToProps,
  {
    resize
  }
)(Header);