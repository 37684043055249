import React, { Component } from 'react';
import { Slider, Rail, Handles, Tracks } from 'react-compound-slider';
import { Handle, Track } from './SliderComponents';
import BaseComponent from '../BaseComponent';

const railStyle = {
  
};

class RangeSlider extends BaseComponent {
  render() {
    const { domain, values, onChange, onUpdate, onSlideStart, step, unit } = this.props;
    const dots = []
    const dotsCount = (domain[1]-domain[0])/step;
    const current = (values[0]-domain[0])/step;
    for (var i = 1; i < dotsCount; i++) {
      dots.push(<li key={'dot-'+i} className={i > current ? "dot" : "dot active"} style={{ left: (100 / dotsCount * i)+'%' }}></li>)  
    } 

    return (
      <Slider
        className="range-slider"
        mode={1}
        step={step}
        domain={domain}
        onUpdate={onUpdate}
        onChange={onChange}
        onSlideStart={onSlideStart}
        values={values}
      >
        <Rail>
          {({ getRailProps }) => <div className="rail" style={railStyle} {...getRailProps()}>
            <div className="rail-inner">
              { this.props.showDots ?
                <ul className="dots">
                  { dots }
                </ul>
                : null
              }
            </div>
          </div>
          }
        </Rail>
        <Handles>
          {({ handles, getHandleProps }) => (
            <div className="handles">
              {handles.map(handle => (
                <Handle
                  key={handle.id}
                  handle={handle}
                  domain={domain}
                  getHandleProps={getHandleProps}
                />
              ))}
            </div>
          )}
        </Handles>
        <Tracks right={false}>
          {({ tracks, getTrackProps }) => (
            <div className="track-wrap">
              {tracks.map(({ id, source, target }) => (
                <Track
                  key={id}
                  source={source}
                  target={target}
                  getTrackProps={getTrackProps}
                />
              ))}
            </div>
          )}
        </Tracks>
        <div className="min-value">{ this.thousandSep(domain[0])+unit }</div>
        <div className="max-value">{ this.thousandSep(domain[1])+unit }</div>
      </Slider>
    );
  }
}

export default RangeSlider;
