export const loadState = () => {
	try {
		const serializedState = localStorage.getItem('state');

		if (serializedState === null) {
			return { 
				financing: {
					car_price: 78900,
					selected: 0
				},
				config: {
					preloader: false,
					initPreloader: true,
					sendComplete: '',
					size: 'maxi' // micro, mini, maxi
				},
				carConfig: {}
			}
		}
		return JSON.parse(serializedState);
	} catch (err) {
		return { 
			financing: {
				car_price: 78900,
				selected: 0
			},
			config: {
				preloader: false,
				initPreloader: true,
				sendComplete: '',
				size: 'maxi' // micro, mini, maxi
			},
			carConfig: {

			}
		}
	}
}

export const saveState = (state) => {
	try {
		const serializedState = JSON.stringify(state);
		localStorage.setItem('state', serializedState);
	} catch (err) {
		// errors
	}
}

export const clearState = () => {
	try {
		console.log(localStorage.getItem('state'))
		localStorage.removeItem('state');
		console.log(localStorage.getItem('state'))
		console.log('clear state')
	} catch (err) {
		// errors
		console.log(err)
	}
}