import { 
  FINANCING_INIT_REQUEST,
  FINANCING_INIT_SUCCESS,
  FINANCING_INIT_FAILURE,
  FINANCING_UPDATE_REQUEST,
  FINANCING_UPDATE_SUCCESS,
  FINANCING_UPDATE_FAILURE,
  SEND_CONFIGURATION_REQUEST,
  SEND_CONFIGURATION_SUCCESS,
  SEND_CONFIGURATION_FAILURE,
  SET_CAR_CONFIG,
  SET_FINANCING_TYPE,
  SET_INITIAL_PAYMENT,
  SET_PAYMENTS_TIME,
  SET_MILAGE,
  TIMER_START,
  TIMER_FINISHED,
  CLEAR_FINANCING,
  OPEN_POPUP,
  CLOSE_POPUP,
  OPEN_RRSO_POPUP,
  CLOSE_RRSO_POPUP
} from './types';
import axios from "axios";
import _ from "lodash";
import { addToast } from './toast'

const URL = 'https://arval-api.tdywidget.com'; 
let resetTimer = 0;

const APP_CONFIG = {
  id: 342, 
  make: "Arval"
}

/*
{
    "id": 999,
    "car_ID": 999,
    "car_title": "Opel",
    "car_details": "Diesel",
    "make": "Arval",
    "model": "Samochód używany",
    "model_year": 2017,
    "production_year": 2017,
    "full_price": 72000,
    "leasing_price": 72000
}
*/
//Arval test app config
/*const APP_CONFIG = {
  id: 999, 
  title: "APP", 
  make: "Arval"
}*/
export function financingInit(data) {
    return (dispatch, getState) => {

    dispatch(setCarConfig(data, false));

    //dispatch(startTimer());
    dispatch({ type: FINANCING_INIT_REQUEST, payload: '' });

    axios.post(URL+'/api/widget/init', 
      { 
        id: APP_CONFIG.id, 
        car_ID: APP_CONFIG.id, 
        car_title: data.model, 
        car_details: data?.car_details, 
        make: APP_CONFIG.make, 
        model: data.model, 
        production_year: data.production_year, 
        production_model_year: data.model_year, 
        full_price: data.full_price, 
        leasing_price: data.car_price,
        financing: {
          params: [
             {
                "ident":"initial",
                "value":"4"
             },
             {
                "ident":"count",
                "value":"1"
             },
             {
                "ident":"additionalRange",
                "value":0
             }
          ]
        }
      })
      .then((response) => {
        if(response.data.data.status) {
          dispatch({type: FINANCING_INIT_SUCCESS, payload: response.data.data.result})
          dispatch({ type: SET_FINANCING_TYPE, payload: response.data.data.result.financing.ident });
        } else {
          dispatch({
            type: FINANCING_INIT_FAILURE,
            payload: response.data.data.result,
            error: response.error
          })
        }
      }).catch((response) => dispatch({
        type: FINANCING_INIT_FAILURE,
        error: response.error
      }))
  }
}

export function financingUpdate(id, param, config) {
    return (dispatch, getState) => { 

    let ident = getState().financing.selected;
    let params = getState().financing.current.params;
    let carConfig = config ? config : getState().financing.carConfig;
    if (id != false && id != undefined) {
      ident = id
    }
    if (param != false && param != undefined) {
      let p = _.find(params, (o)=>{return o.ident === param.ident})
      p.value = param.value;
    }

    //dispatch(startTimer());

    dispatch({ type: FINANCING_UPDATE_REQUEST, payload: '' });

    axios.post(URL+'/api/widget/update',
      { 
        id: APP_CONFIG.id, 
        car_ID: APP_CONFIG.id, 
        car_title: carConfig.model, 
        car_details: '', 
        make: APP_CONFIG.make, 
        model: carConfig.model, 
        production_year: 2019, 
        production_model_year: 2019,
        full_price: carConfig.full_price, 
        leasing_price: carConfig.car_price,
        financing: {
          ident: ident,
          params: params,
        }
      })
      .then((response) => {
        if(response.data.data.status) {
          dispatch({type: FINANCING_UPDATE_SUCCESS, payload: response.data.data.result})
        } else {
          dispatch({
            type: FINANCING_UPDATE_FAILURE,
            error: response.error
          })
        }
      }).catch((response) => dispatch({
        type: FINANCING_UPDATE_FAILURE,
        error: response.error
      }))
  }
}

export function sendCalculation(mail) {
    return (dispatch, getState) => { 

    let ident = getState().financing.selected;
    let params = getState().financing.current.params;
    let carPrice = getState().financing.car_price;

    //dispatch(startTimer());

    dispatch({ type: SEND_CONFIGURATION_REQUEST, payload: '' });

    axios.post(URL+'/api/widget/mail',
      { 
        id: 999, 
        car_ID: 999, 
        car_title: "APP", 
        car_details: '', 
        make: "Arval", 
        model: "APP", 
        production_year: 2020, 
        production_model_year: 2020, 
        full_price: carPrice, 
        leasing_price: carPrice,
        financing: {
          ident: ident,
          params: params,
        },
        mail: mail
      })
      .then((response) => {
        if(response.data.data.status) {
          dispatch({type: SEND_CONFIGURATION_SUCCESS, payload: response.data.data.result})
        }
      }).catch((response) => dispatch({
        type: SEND_CONFIGURATION_FAILURE,
        error: response.error
      }))
  }
}



export function setCarConfig(config, update) {
  return (dispatch) => {
    //dispatch(startTimer());
    if (update) {
      dispatch(financingUpdate(false, false, config))
    }
    dispatch({ type: SET_CAR_CONFIG, payload: config });
  }
}

/*export function setFinancingType(key) {
  return (dispatch, getState) => {
    const ident = getState().financing.products[key].ident;

    dispatch(financingUpdate(ident))

    dispatch({ type: SET_FINANCING_TYPE, payload: ident });
  }
}*/

export function setFinancingType(key) {
  return (dispatch, getState) => {
    const ident = getState().financing.products[key].ident;
    let carConfig = getState().financing.carConfig;

    //dispatch(startTimer());

    dispatch({ type: FINANCING_UPDATE_REQUEST, payload: '' });

    axios.post(URL+'/api/widget/update',
      { 
        id: APP_CONFIG.id, 
        car_ID: APP_CONFIG.id, 
        car_title: carConfig.model, 
        car_details: '', 
        make: APP_CONFIG.make, 
        model: carConfig.model, 
        production_year: 2019, 
        production_model_year: 2019,
        full_price: carConfig.full_price, 
        leasing_price: carConfig.car_price,
        financing: {
          ident: ident,
          params: getState().financing.products[key].params,
        }
      })
      .then((response) => {
        if(response.data.data.status) {
          dispatch({
            type: FINANCING_UPDATE_SUCCESS,
            payload: response.data.data.result
          })
        }
      }).catch((response) => dispatch({
        type: FINANCING_UPDATE_FAILURE,
        error: response.error
      }))

    dispatch({ type: SET_FINANCING_TYPE, payload: ident });
  }
}

export function setInitialPayment(value, update) {
  return (dispatch) => {
    if (update) {
      dispatch(financingUpdate(false, {ident: 'initial', value: value}))
    }
    //dispatch(startTimer());
    dispatch({ type: SET_INITIAL_PAYMENT, payload: value });
  }
}

export function setPaymentsTime(value, update) {
  return (dispatch) => {
    if (update) {
      dispatch(financingUpdate(false, {ident: 'count', value: value}))
    }
    //dispatch(startTimer());
    dispatch({ type: SET_PAYMENTS_TIME, payload: value });
  }
}

export function setMilage(value) {
  return (dispatch) => {
    dispatch(financingUpdate(false, {ident: 'additionalRange', value: value}))
    //dispatch(startTimer());
    dispatch({ type: SET_MILAGE, payload: value });
  }
}

export function startTimer() {
  return (dispatch) => {
    clearTimeout(resetTimer);
    //dispatch({ type: TIMER_START, payload: '' });
    resetTimer = window.setTimeout(()=>{
      dispatch({ type: TIMER_FINISHED, payload: '' });
    }, 180000)
  }
}

export function clearFinancing() {
  return (dispatch) => {
    dispatch({ type: CLEAR_FINANCING, payload: '' });
  }
}

export function openPopup() {
  return (dispatch) => {
    //dispatch(startTimer());
    dispatch({ type: OPEN_POPUP, payload: '' });
  }
}

export function closePopup() {
  return (dispatch) => {
    //dispatch(startTimer());
    dispatch({ type: CLOSE_POPUP, payload: '' });
  }
}

export function openRrsoPopup() {
  return (dispatch) => {
    //dispatch(startTimer());
    dispatch({ type: OPEN_RRSO_POPUP, payload: '' });
  }
}

export function closeRrsoPopup() {
  return (dispatch) => {
    //dispatch(startTimer());
    dispatch({ type: CLOSE_RRSO_POPUP, payload: '' });
  }
}