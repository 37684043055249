import React, { Component } from 'react';
import { connect } from "react-redux";
import _ from 'lodash';
import BaseComponent from "../components/BaseComponent";
import Widget from "../components/Widget";
import Summary from "../components/Summary";
import { saveDimensionData } from '../actions/screen';
import { financingInit, closePopup, closeRrsoPopup, sendCalculation, setCarConfig } from '../actions/financing';
import { closeSummaryPopup } from '../actions/config';
import Button from '../components/Button';
import Modal from "../components/Modal";

class App extends BaseComponent {
  constructor(props) {
    super(props)
    this.state = {
      timeLeft: 20, 
      email: '',
      car_title: '',
      car_details: '',
      model: '',
      model_year: 0,
      production_year: 0,
      car_price: 0,
      full_price: 0,
      leasing_price: 0,
      model_raw: ''
    };
  }

  componentWillMount() {
    //this.props.navigateHome();
    //console.log('////////////////////////////////////// MOUNTING APP ////////////////////////////////////////')

    // TEST DATA KIA {"model":"Kia e-Soul 136 39.2kWh L","addons":"2000","mileage":"15000","months":"36"}

    window.SCF = {
      init: (data) => {
        //console.log('init')
        //externalReady = true
        if (!_.isEmpty(data)) {
          this.props.financingInit(data);
        }
      },

      get: () => {
        return {car_config: this.props.carConfig , financing: this.props.financing, userAction: ""}
      },

      set: (data) => {
        //console.log('set')
        this.props.setCarConfig(data, true)
      },

      pdf: () => {
        //console.log('pdf')
        //this.dispatch.trigger( Events.SEND_TRACKING_DATA  )
        //return this.model.attributes.data.financing.pdf_template;
      }
    }

    this.updateDimensions();
  }

  componentDidMount() {
    console.log(process.env.REACT_APP_ENV)
    if (process.env.REACT_APP_ENV === 'development') {

      let userTestData = JSON.parse(localStorage.getItem('testdata'));

      const basicData = {
        financing: {
          params: [
            { ident: 'initial', value: 0 },
            { ident: 'count', value: 0 },
            { ident: 'additionalRange', value: 0 } 
          ]
        }
      }

      if (userTestData) {
        userTestData = {...userTestData, basicData}
        this.setState({
          price: userTestData.price,
          leasing_price: userTestData.leasing_price,
          full_price: userTestData.full_price,
          car_title: userTestData.car_title, 
          car_details: userTestData.car_details, 
          car_price: userTestData.car_price, 
          model: userTestData.model, 
          model_year: userTestData.model_year, 
          production_year: userTestData.production_year, 
        })
      }
      
      const testDataSuccess = {
        car_title: "Opel", 
        car_details: "Diesel", 
        car_price: 2500, 
        model: "Samochód używany", 
        model_year: 2020, 
        production_year: 2020, 
        full_price: 2500, 
        leasing_price: 2500,
        financing: {
          params: [
            { ident: 'initial', value: 0 },
            { ident: 'count', value: 0 },
            { ident: 'additionalRange', value: 0 } 
          ]
        }
      }

      this.props.financingInit(userTestData ? userTestData : testDataSuccess);
    } else {
      //const testData = {"model":"Kia Niro 1.6GDi 141KM Hyb. L AT 5d","addons":"2000","mileage":"15000","months":"36", "car_price": 78900, "full_price": 78900, "year": 2019, "model_year": 2019};
      //this.props.financingInit(testData);
      // production code
    }

    window.addEventListener('resize', this.updateDimensions.bind(this));

    window.addEventListener( 'message', (event) => {
      if (event.origin === "https://autoselect.arval.pl" || event.origin === "https://autoselect-uat-pl.makolab.com") {
        if (event.data.type === 'init') {
          window.SCF.init(event.data.data);
        }
        if (event.data.type === 'set') {
          window.SCF.set(event.data.data);
        }
      } else {
        return
      }
    }, false);

    window.parent.postMessage("ready", "*");
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions.bind(this));
  }

  updateDimensions() {
    this.props.saveDimensionData({
      height: window.innerHeight,
      width: window.innerWidth
    })
  }

  getProduct() {
    return _.find(this.props.financing.products, (o)=>{ return o.ident === this.props.financing.current.ident });
  }

  render() {
    const product = this.getProduct();
    const isLeasing = !this.props.financing.current || this.props.financing.current.rrso === '' ? true : false;
    
    const userTestData = JSON.parse(localStorage.getItem('testdata'));

    return (
      <div className="app">
        {
          process.env.REACT_APP_ENV === 'development' &&
          <div className="test-data-form">
             <div className="input">
              <label>car title</label>
              <input onChange={(e)=>this.setState({car_title: e.currentTarget.value})} defaultValue={userTestData?.car_title} />
            </div>
            <div className="input">
              <label>car details</label>
              <input onChange={(e)=>this.setState({car_details: e.currentTarget.value})} defaultValue={userTestData?.car_details} />
            </div>
            <div className="input">
              <label>model</label>
              <input onChange={(e)=>this.setState({model: e.currentTarget.value})} defaultValue={userTestData?.model} />
            </div>
            <div className="input">
              <label>model year</label>
              <input onChange={(e)=>this.setState({model_year: e.currentTarget.value})} defaultValue={userTestData?.model_year} />
            </div>
            <div className="input">
              <label>production year</label>
              <input onChange={(e)=>this.setState({production_year: e.currentTarget.value})} defaultValue={userTestData?.production_year} />
            </div>
            <div className="input">
              <label>car price</label>
              <input onChange={(e)=>this.setState({car_price: e.currentTarget.value})} defaultValue={userTestData?.car_price} />
            </div>
            <div className="input">
              <label>full price</label>
              <input onChange={(e)=>this.setState({full_price: e.currentTarget.value})} defaultValue={userTestData?.full_price} />
            </div>
            <div className="input">
              <label>leasing price</label>
              <input onChange={(e)=>this.setState({leasing_price: e.currentTarget.value})} defaultValue={userTestData?.leasing_price} />
            </div>
            <Button action={()=>{
              localStorage.setItem('testdata', JSON.stringify({
                price: this.state.price,
                leasing_price: this.state.leasing_price,
                full_price: this.state.full_price,
                car_title: this.state.car_title, 
                car_details: this.state.car_details, 
                car_price: this.state.car_price, 
                model: this.state.model, 
                model_year: this.state.model_year, 
                production_year: this.state.production_year
              }));
              window.location.reload();
            }}>zapisz</Button>
          </div>
        }
        { this.props.config.initPreloader || this.props.config.initPreloader === undefined ?
          <div className="init-preloader"></div> :
          <Widget />
        }
        { this.props.config.popup ?
          <div className="popup">
            <div className="popup-inner">
              <form className="calc-form">
                <label htmlFor="email">Wpisz adres e-mail: </label>
                <input type="email" name="email" id="email" onChange={ (e) => { this.setState({ email: e.target.value }) } } value={ this.state.email } required />
                <div className="checkboxes">
                  <div className="checkbox">
                    <input type="checkbox" id="check1" name="check1" required/>
                    { isLeasing ? 
                    <label htmlFor="check1">Wyrażam zgodę, na przetwarzanie moich danych osobowych podanych w formularzu przez BNP Paribas Leasing Solution sp. z o.o. jako administratora danych osobowych w celu prowadzenia marketingu, w tym przekazywania mi informacji o produktach i usługach oferowanych przez Administratora.</label> :
                    <label htmlFor="check1">Chcę otrzymać przedstawianą informację handlową na podany adres e-mail. Przyjmuję do wiadomości, że podanie adresu e-mail jest dobrowolne, ale wysłanie formularza jest równoznaczne ze zgodą na jednorazowe przesłanie ww. informacji. Po jej przesłaniu, adres e-mail zostanie trwale usunięty. Administratorem danych osobowych jest BNP Paribas Bank Polska S.A. Zapoznaj się z pełną informacją o przetwarzaniu danych osobowych, przy czym informacja o adresie e-mail jest udostępniana świadczącemu na rzecz Banku ww. usługę, a adres e-mail zostanie  trwale usunięty niezwłocznie po przesłaniu żądanej informacji handlowej.</label>
                    }
                  </div>
                  { isLeasing ? 
                  <div className="checkbox">
                    <input type="checkbox" id="check2" name="check2" required/>
                    <label htmlFor="check2">Wyrażam zgodę, zgodnie z ustawą z dnia 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną na otrzymywanie drogą elektroniczną informacji stanowiących informacje handlowe w rozumieniu ww. ustawy, w tym wiadomości  SMS oraz email, dotyczących nowych produktów, usług, promocji produktów BNP Paribas Leasing Services Sp. z o.o.. oferowanych przez BNP Paribas Bank Polska S.A. z siedzibą w Warszawie jako pośrednika. Klauzula Informacyjna zawierająca informacje o: BNP Paribas Leasing Services Sp. z o.o. z siedzibą w Warszawie jako Administratorze oraz zasadach przetwarzania danych osobowych jest dostępna pod linkiem <a href="https://leasingsolutions.bnpparibas.pl/daneosobowe/">{"https://leasingsolutions.bnpparibas.pl/daneosobowe/"}</a></label>
                  </div> : null }
                </div>
                <Button type="small" action={ this.validate }>Wyślij</Button>
              </form>
              <div className={ this.props.config.sendComplete != '' ? "popup-message show" : "popup-message" }>
                <h2>{ this.props.config.sendComplete === 'success' ? "Konfiguracja przesłana" : "Wystąpił błąd" }</h2>
              </div>
              <div className="popup-close" onClick={ ()=>{ this.props.closePopup() } }><img src="/assets/zamknij.svg" /></div>
              <div className={ this.props.config.sendPreloader ? "preloader-holder show" : "preloader-holder" }>
                <div id="preloader" className={ this.state.loading ? "preloader show" : "preloader" }><span></span></div>
              </div>
            </div>
          </div> 
          : null
        }
        { this.props.config.popupRRSO ?
          <div className="popup rrso-popup">
            <div className="popup-inner">
              <div dangerouslySetInnerHTML={{ __html: this.removeWhiteSpaceFromNumbers(product.disclaimer_2) }}></div>
              <div className="popup-close" onClick={ ()=>{ this.props.closeRrsoPopup() } }>
                <svg viewBox="0 0 33 33">
                    <g fill="none">
                        <rect width="22" height="3" x="5" y="15" fill="#000" rx="1.5" transform="rotate(-135 16 16.5)"/>
                        <rect width="22" height="3" x="5" y="15" fill="#000" rx="1.5" transform="scale(1 -1) rotate(45 55.835 0)"/>
                    </g>
                </svg>
              </div>
            </div>
          </div> 
          : null
        }
        <Modal
          open={this.props.config.popupSummary}
          hidePopup={() => {
            this.props.closeSummaryPopup()
          }}
        >
          <div className="summary-popup popup-inner">
            <Summary/>
          </div>
        </Modal>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const { screen, financing, config } = state
  return { screen, financing, config }
};

export default connect(
  mapStateToProps,
  {
    saveDimensionData,
    closePopup,
    closeRrsoPopup,
    sendCalculation,
    financingInit,
    setCarConfig,
    closeSummaryPopup
  }
)(App);